html {
  font-family: Arial;
}

textarea {
  width: 300px;
  height: 100px;
}

.app {
  text-align: center;
}

button {
  box-shadow: inset 0px 1px 0px 0px #bbdaf7;
  background-color: rgb(88,184,146);
  border-radius: 6px;
  border: 1px solid #378de5;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 15px;
  font-weight: bold;
  padding: 6px 24px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #528ecc;
}

img {
  max-width: 500px;
}